@value small-and-up from '../lib/definitions.module.css';

.link {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1.5em;
}

.link[data-showing-blog='true'] {
  margin-bottom: 0;
}

/* stylelint-disable-next-line media-feature-name-no-unknown */
@media (small-and-up) {
  .link {
    width: 50%;
  }

  .link[data-showing-blog='true'],
  .link[data-full-width='true'] {
    width: 100%;
  }
}

.linkColor {
  color: var(--link);
}

.linkColor:hover {
  color: var(--link-hover);
}

.link[data-started='true'] .linkColor {
  color: var(--error);
}

.link[data-completed='true'] .linkColor {
  color: var(--text);
}

.link[data-started='true'] .linkColor:hover {
  color: var(--error-hover);
}

.link[data-completed='true'] .linkColor:hover {
  color: var(--text);
}

.icon {
  margin-right: 0.3em;
  font-size: 4em;
  line-height: 1em;
}

.emoji {
  position: absolute;
  top: 0;
  left: 0;

  width: 1.66em;

  font-size: 0.6em;
  text-align: center;
  text-shadow:
    2px 0 0 white,
    -2px 0 0 white,
    0 2px 0 white,
    0 -2px 0 white;
}

.taglist {
  font-size: 0.9em;
}

.follow {
  min-width: 5.2em;
  margin-left: 0.5em;
  padding: 0.25em;

  font-size: 0.9em;

  box-shadow: none;
}

.blog {
  width: 100%;
  margin-bottom: 2em;
}
