@value huge-and-up from '../lib/definitions.module.css';
@value max-width from '../lib/definitions.module.css';

.baseurl {
  margin-right: 0.15em;
  font-weight: bold;
}

.header {
  margin: 2em 1em;
}

/* stylelint-disable-next-line media-feature-name-no-unknown */
@media (huge-and-up) {
  .header {
    /* stylelint-disable-next-line declaration-property-value-no-unknown */
    max-width: max-width;
    margin: 2em auto;
  }
}

.patronicon {
  margin-right: 0.3em;
}

.byline {
  margin-bottom: 0.25em;
  font-size: 1em;
  font-weight: normal;
}
