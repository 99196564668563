.spoiler[data-hidden='true'] {
  cursor: pointer;
  user-select: none;
  color: rgb(0 0 0 / 0%);
  background-color: var(--text);
}

.spoiler[data-hidden='true'] * {
  visibility: hidden;
}
