.reset {
  cursor: pointer;

  padding: 0;

  font-family: inherit;
  font-weight: normal;
  color: inherit;
  text-decoration: none;

  background-color: transparent;
  border: none;
}

.reset:disabled {
  cursor: default;
  color: var(--default-text);
}

.reset[data-hover-text]:not([data-hover-text='']):hover span {
  display: none;
}

.reset[data-hover-text]:not([data-hover-text='']):hover::after {
  content: attr(data-hover-text);
}

.link {
  color: var(--link);
}

.link:hover {
  color: var(--link-hover);
  text-decoration: underline;
}

.link:disabled {
  text-decoration: none;
}

.link:hover:disabled {
  color: var(--default-text);
}

.btn {
  max-width: 100%;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;
  padding: 0.5em 1em;

  line-height: 1.1;
  color: var(--onlink);
  text-align: center;

  background-color: var(--link);

  /* invisible border (will be colored on hover/focus) */
  border: solid 1px transparent;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 50%);
}

.btn[data-boring='true'] {
  color: var(--onboring);
  background-color: var(--boring-bg);
}

.btn[data-hollow='true'] {
  color: var(--link);
  background-color: transparent;
  border-color: var(--link);
}

.btn:disabled {
  color: var(--default-text);
  background-color: transparent;
  border-color: var(--default-text);
}

.btn:enabled:active {
  transform: translateY(1px);
}

@media (hover: hover) {
  .btn[data-boring='true']:hover:enabled {
    color: var(--onboring);
    background-color: var(--boring-bg-hover);
  }

  .btn:hover {
    color: var(--onlink);
    text-decoration: none;
    background-color: var(--link-hover);
  }

  .btn:hover:disabled {
    color: var(--default-text);
    background-color: transparent;
    border-color: var(--default-text);
  }
}
