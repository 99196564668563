@value small-and-up from '../lib/definitions.module.css';
@value large-and-up from '../lib/definitions.module.css';
@value huge-and-up from '../lib/definitions.module.css';

.stats {
  column-count: 1;
  margin-bottom: 2em;
}

/* stylelint-disable-next-line media-feature-name-no-unknown */
@media (small-and-up) {
  .stats {
    column-count: 2;
  }
}

/* stylelint-disable-next-line media-feature-name-no-unknown */
@media (large-and-up) {
  .stats {
    column-count: 3;
  }
}

/* stylelint-disable-next-line media-feature-name-no-unknown */
@media (huge-and-up) {
  .stats {
    column-count: 4;
  }
}

.section {
  break-inside: avoid-column;
}
