@value small-and-up from '../lib/definitions.module.css';

.overlay {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;

  overflow-y: scroll;
  overscroll-behavior: contain;
  display: block;

  width: 100%;
  height: 100%;

  background-color: rgb(0 0 0 / 85%);
}

.overlay[data-hidden='true'] {
  display: none;
}

.inner {
  position: relative;

  width: 95%;
  max-width: 1200px;
  margin: 1em auto;

  background-color: var(--overlay-inner);
  border: 1px solid var(--overlay-stroke);
}

.overlay[data-slate='true'] .inner {
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  border-radius: 7px;
}

/* stylelint-disable-next-line media-feature-name-no-unknown */
@media (small-and-up) {
  .inner {
    width: 90%;
    margin: 2em auto;
  }
}

.closeButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  width: 1em;
  height: 1em;
  padding: 0;

  font-size: 2.5em;
  color: var(--text);
  vertical-align: text-top;

  background: transparent;
  border: none;
}

.overlay[data-cover='true'] .closeButton {
  color: var(--social-text);
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
}
