.tag {
  padding: 0.2em 0.5em;

  color: var(--text);
  white-space: nowrap;

  background-color: hsl(var(--hue) 30% var(--tag-l));
  border-radius: 5px;
}

.tag[data-has-hover='true']:hover {
  background-color: hsl(var(--hue) 60% var(--tag-l));
}

.removeBtn {
  margin-left: 0.4em;
  padding-left: 0.4em;
  border-left: 1px solid var(--text);
}

.link:hover {
  text-decoration: none;
}
